import React, { useEffect, useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import {
  StyledProductList,
  StyledProductListItem,
} from "./Products/productArchive"
import PrimaryProductCard from "./Products/PrimaryProductCard"
import PermissionBonusCard from "./Products/PermissionBonusCard"
import PermissionResetNervousSystem from "./Products/PermissionResetNervousSystem"
import PermissioningPractitioner from "./Products/PermissioningPractitioner"
import PermissioningManifestingCard from "./Products/PermissioningManifesting"
import SpoonTherapy from "./Products/SpoonTherapy"
import CommandHealing from "./Products/CommandHealing"
import FtmjHealingTuningBlueprint from "./Products/FmtjHealingTuningBlueprint"
import MorningDailyIntention from "./Products/MorningDailyIntention"
import YourMiraculousLifeClub from "./Products/YourMiraculousLifeClub"
import PermissionResetNervousSystemJuly2023Workshop from "./Products/PermissionResetNervousSystemJuly2023Workshop"
import PrimaryProductCardPurchased from "./Products/PrimaryProductCardPurchased"
import { ProductExcerpt, ProductFigure, ProductFigureCaption, ProductHeader, StyledProductPrimaryCard } from "./Products/StyledPrimaryProductCard"
import { StyledButton } from "../styledComponents/button"

const ProductListPurchased = ({ posts, pageType, padding, type, roles, isAuthenticated, user }) => {
  const [countryCode, setCountryCode] = useState("") // store token
  const identity = useIdentityContext()
  useEffect(() => {
    fetch('/geolocation', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (geoLocationData) {
        setCountryCode(geoLocationData?.geo?.country?.code)
      })
  }, [])
  return (

    <StyledProductList pageType={pageType} padding={padding}>

      {isAuthenticated && (roles?.includes('Morning Daily Intention')) &&
        <StyledProductListItem key='morning-daily-intention' type='morning-daily-intention'>
          <MorningDailyIntention
            title="Morning Daily Intention"
            id="cG9zdDo3OTI5"
            excerpt='Watch the recording of the Spoon Therapy Essentials is taught by Kaye Lee, the creator of the method.'
            featuredImage='/images/spoon-therapy-min.jpg'
          />
        </StyledProductListItem>
      }
      {isAuthenticated && (roles?.includes('5 Master Healing Systems') || (roles.includes('Healing Access Codes Package B')) || (roles.includes('Complete Brain Reboot Package B'))) &&
        <StyledProductListItem key='5-Master-Healing-Systems' type='5 Master Healing Systems'>
          <a href="/program/5-master-healing-systems/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/5-master-healing-systems.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">5 Master Healing Systems</ProductHeader>
              <ProductExcerpt>Access Kaye's 5 Master Healing System Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('IdealWeight Intelligent Energy Drops')) &&
        <StyledProductListItem key='IdealWeight-Intelligent-Energy-Drops' type='IdealWeight-Intelligent-Energy-Drops'>
          <a href="/program/ideal-weight-intelligent-energy-drops/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/ideal-weight-intelligent-energy-drops-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">IdealWeight Intelligent Energy Drops</ProductHeader>
              <ProductExcerpt>Access Kaye's IdealWeight Intelligent Energy Drops Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Divine Reversal Codes')) &&
        <StyledProductListItem key='Divine Reversal Codes' type='Divine Reversal Codes'>
          <a href="/program/divine-reversal-codes/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/divine-reversal-codes-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                Divine Reversal Codes
              </ProductHeader>
              <ProductExcerpt>Access your Divine Reversal Codes Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Complete Brain Reboot Package A')) &&
        <StyledProductListItem key='Complete Brain Reboot' type='Complete Brain Reboot'>
          <a href="/program/complete-brain-reboot/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/complete-brain-reboot-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                Complete Brain Reboot
              </ProductHeader>
              <ProductExcerpt>Access your Complete Brain Reboot Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Master Health Grid Package A') || (roles.includes('Master Health Grid Package B')) || (roles.includes('Master Health Grid Package C'))) &&
        <StyledProductListItem key='Master Health Grid' type='Master Health Grid'>
          <a href="/program/master-health-grid/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/master-health-grid-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                Master Health Grid
              </ProductHeader>
              <ProductExcerpt>Access Karina's Master Health Grid Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Complete Body Rewind Package A') || (roles.includes('Complete Body Rewind Package B'))) &&
        <StyledProductListItem key='Complete-Body-Rewind' type='Complete Body Rewind'>
          <a href="/program/complete-body-rewind/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/complete-body-rewind.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                {`Complete Body Rewind ${roles.includes('Complete Body Rewind Package B') ? '- B' :
                  roles.includes('Complete Body Rewind Package A') ? '- A' : ''
                  }`}
              </ProductHeader>
              <ProductExcerpt>Access Karina's Complete Body Rewind Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Abundance Access Codes Package A') || (roles.includes('Abundance Access Codes Package B'))) &&
        <StyledProductListItem key='Abundance-Access-Codes' type='Abundance Access Codes'>
          <a href="/program/abundance-access-codes/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/abundance-access-codes-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                {`Abundance Access Codes ${roles.includes('Abundance Access Codes Package B') ? '- B' :
                  roles.includes('Abundance Access Codes Package A') ? '- A' : ''
                  }`}
              </ProductHeader>
              <ProductExcerpt>Access Kaye's Abundance Access Codes Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Heartwall Access Codes Package A') || (roles.includes('Heartwall Access Codes Package B'))) &&
        <StyledProductListItem key='Heartwall-Access-Codes' type='Heartwall Access Codes'>
          <a href="/program/heartwall-access-codes/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/heartwall-access-codes-min.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">
                {`Heartwall Access Codes ${roles.includes('Heartwall Access Codes Package B') ? '- B' :
                  roles.includes('Heartwall Access Codes Package A') ? '- A' : ''
                  }`}
              </ProductHeader>
              <ProductExcerpt>Access Kaye's Heartwall Access Codes Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Healing Access Codes Package A') || (roles.includes('Healing Access Codes Package B'))) &&
        <StyledProductListItem key='Healing-Access-Codes' type='Healing Access Codes'>
          <a href="/program/healing-access-codes/program" style={{ textDecoration: 'none', color: 'inherit' }}>
            <StyledProductPrimaryCard
              itemScope
              itemType="http://schema.org/Product"
            >
              <ProductFigure>
                <img
                  src="/images/program/healing-access-codes.png"
                  width="100%"
                  padding-bottom="0"
                />
              </ProductFigure>
              <ProductHeader itemProp="name">Healing Access Codes</ProductHeader>
              <ProductExcerpt>Access Kaye's Healing Access Codes Program</ProductExcerpt>
              <StyledButton className="listen-now">Enter</StyledButton>
            </StyledProductPrimaryCard>
          </a>
        </StyledProductListItem>

      }

      {isAuthenticated && (roles?.includes('Your Miraculous Life Club - Round 1')) &&
        <StyledProductListItem key='Your-Miraculous-Life-Club' type='morning-daily-intention'>
          <YourMiraculousLifeClub
            title="Your Miraculous Life Club"
            id="cG9zdDo3OTI5"
            excerpt='Watch the replays of the Your Miraculous Life Club.'
            featuredImage='https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png'
          />
        </StyledProductListItem>
      }

      {isAuthenticated && (roles?.includes('Command Healing') || roles?.includes('Divine Reversal Codes')) &&
        <StyledProductListItem key='command-healing' type='command-healing'>
          <CommandHealing
            title="Command Healing"
            id="cG9zdDo4FDkz"
            excerpt='Watch the recording of the Command Healing for Distress & Trauma taught by Kaye Lee, the creator of the method.'
            featuredImage='/images/command-healing-min.jpg'
          />
        </StyledProductListItem>
      }


      {isAuthenticated && (roles?.includes('Spoon Therapy')) &&
        <StyledProductListItem key='spoon-therapy' type='spoon-therapy'>
          <SpoonTherapy
            title="Spoon Therapy"
            id="cG9zdDo3OTI5"
            excerpt='Watch the recording of the Spoon Therapy Essentials is taught by Kaye Lee, the creator of the method.'
            featuredImage='/images/spoon-therapy-min.jpg'
          />
        </StyledProductListItem>
      }
      {isAuthenticated && (roles?.includes('Permission Bonus') || roles?.includes('Divine Reversal Codes')) &&
        <StyledProductListItem key='sss' type='pat-bonus'>
          <PermissionBonusCard
            title="Permission Bonus audios"
            id="cG9zdDo3OTI5"
            excerpt='Listen to these 12 permissions; Boundless Gratitude, Eat Healthy Without Feeling Deprived, Mind, Body &amp; Spirit In Sync, Self Confident In Any Situation, Your Body Is Your Temple plus others'
            featuredImage='/images/permissioning-buttefly.jpg'
          />

        </StyledProductListItem>
      }

      {isAuthenticated && (roles?.includes('Permission Reset Your Nervous System Online Audio')) &&
        <StyledProductListItem key='a' type='permission-to-reset-nervous-system'>
          <PermissionResetNervousSystem
            title="Permission to Reset your Nervous System"
            id="cG9zdDo3OTI5"
            excerpt='Audio recording from the online workshop with Kaye &amp; Karina.'
            featuredImage='/images/permissioning-buttefly.jpg'
          />

        </StyledProductListItem>
      }

      {isAuthenticated && (roles?.includes('Permission Reset Your Nervous System Online July 2023 Workshop')) &&
        <StyledProductListItem key='a' type='permission-to-reset-nervous-system'>
          <PermissionResetNervousSystemJuly2023Workshop
            title="Permission to Reset your Nervous System"
            id="cG9zdDo3OTI5"
            excerpt='Recording from the online workshop with Karina in July 2023.'
            featuredImage='/images/permissioning-buttefly.jpg'
          />

        </StyledProductListItem>
      }

      {isAuthenticated && (roles?.includes('Permissioning Practitioner')) &&
        <StyledProductListItem key='practitioner' type='permissioning-practitioner'>
          <PermissioningPractitioner
            title="Permissioning Practitioner Program"
            id="cG9zdDo3OTI5"
            excerpt='Video recording from the online workshop with Kaye &amp; Karina.'
            featuredImage='/images/permissioning-buttefly.jpg'
          />
        </StyledProductListItem>
      }

      {isAuthenticated && (roles?.includes('Permissioning Manifesting')) &&
        <StyledProductListItem key='manifesting' type='permissioning-manifesting'>
          <PermissioningManifestingCard
            title="Permissioning Manifesting"
            id="cG9zdDo3OTI5"
            excerpt='Audio recording from the Permissioning™ Manifesting webinar with Karina.'
            featuredImage='/images/permissioning-manifesting.jpg'
          />
        </StyledProductListItem>
      }
      {posts.map((post, index) => {
        return (
          <>
            {!post.node.productData.hideonarchive &&
              // <StyledProductListItem key={post.node.id} type={type}>
              <PrimaryProductCardPurchased
                title={post.node.title}
                id={post.node.id}
                uri={post.node.uri}
                excerpt={post.node.excerpt}
                featuredImage={post.node.featuredImage}
                pricefull={countryCode === 'US' ? post.node.productData.pricefullusd : post.node.productData.pricefull}
                pricesale={countryCode === 'US' ? post.node.productData.pricesaleusd : post.node.productData.pricesale}
                hidesale={post.node.productData.hidesale}
                amazonLink={post.node.productData.linkToAmazon}
                stripefullprice={countryCode === 'US' ? post.node.productData.stripefullpriceusd : post.node.productData.stripefullprice}
                stripesaleprice={countryCode === 'US' ? post.node.productData.stripesalepriceusd : post.node.productData.stripesaleprice}
                productTag={post.node.productsTag.nodes[0]?.slug}
                currency={countryCode === 'US' ? '$' : '£'}
                isAuthenticated={isAuthenticated}
                roles={roles}
                user={user}
              />
              // </StyledProductListItem>
            }
          </>
        )
      })}

      {isAuthenticated && (roles?.includes('healing-tuning-blueprint') || isAuthenticated.app_metadata?.roles?.includes('healing-tuning-blueprint')) &&
        <StyledProductListItem key='healing-tuning-blueprint' type='healing-tuning-blueprint'>
          <FtmjHealingTuningBlueprint
            title="Human Tuning Blueprint"
            id="cG9zdDo3OTI5"
            excerpt='Your human tuning blueprint audios.'
            featuredImage='/images/human-tuning-blueprint-min.jpg'
          />
        </StyledProductListItem>
      }
    </StyledProductList>
  )
}

export default ProductListPurchased
